import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getThemeById, updateTheme, getThemeVersions } from '../helpers/api/services/themesService';
import AddThemeVersion from './AddThemeVersion';
import showToast from '../helpers/notifications/showToast';
import { STRAPI_URL } from '../helpers/config';
import '../styles/edit-theme.css';
import { Modal, Button, Carousel } from 'react-bootstrap';

const EditTheme = () => {
  const { id } = useParams();
  const [name, setName] = useState('');
  const [version, setVersion] = useState('');
  const [isCustomizable, setIsCustomizable] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const [lastVersionSnapshots, setLastVersionSnapshots] = useState([]);
  const [showVersionForm, setShowVersionForm] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTheme = async () => {
      try {
        const themeData = await getThemeById(id);
        setName(themeData.attributes.name);
        setIsCustomizable(themeData.attributes.isCustomizable);
        setCustomFields(themeData.attributes.customFields);

        const lastVersion = await getThemeVersions(id);
        if (lastVersion && lastVersion.attributes.version) {
          setVersion(lastVersion.attributes.version);
        }
        if (lastVersion && lastVersion.attributes.snapshots.data.length > 0) {
          setLastVersionSnapshots(lastVersion.attributes.snapshots.data.map(snapshot => snapshot.attributes));
        }
      } catch (error) {
        console.error('Error fetching theme', error);
        showToast('Error fetching theme', 'error');
      }
    };

    fetchTheme();
  }, [id]);

  const handleCustomFieldChange = (index, value) => {
    const newCustomFields = [...customFields];
    newCustomFields[index] = value;
    setCustomFields(newCustomFields);
  };

  const handleAddCustomField = () => {
    setCustomFields([...customFields, '']);
  };

  const handleRemoveCustomField = (index) => {
    const newCustomFields = customFields.filter((_, i) => i !== index);
    setCustomFields(newCustomFields);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isCustomizable && customFields.length === 0) {
      showToast('Please add at least one custom field', 'warning');
      return;
    }
    try {
      await updateTheme(id, { name, isCustomizable, customFields });
      showToast('Theme updated successfully!', 'success');
      navigate('/themes');
    } catch (error) {
      console.error('Error updating theme', error);
      showToast('Error updating theme', 'error');
    }
  };

  const handleVersionAdded = () => {
    setShowVersionForm(false);
    showToast('New version added successfully!', 'success');
  };

  return (
    <div className="container edit-theme-container">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2 className="edit-theme-title">Edit Theme</h2>
        <Button variant="info" onClick={() => setShowVersionForm(true)}>
          <i className="bi bi-plus-circle"></i> New Version
        </Button>
      </div>
      <form onSubmit={handleSubmit} className="edit-theme-form">
        <div className="edit-theme-form-group">
          <label htmlFor="name" className="edit-theme-form-label">Name</label>
          <input
            type="text"
            className="edit-theme-form-control"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group edit-theme-form-group">
          <label htmlFor="version" className="form-label edit-theme-form-label">Version</label>
          <input
            type="text"
            className="form-control edit-theme-form-control"
            id="version"
            value={version}
            disabled
          />
        </div>
        <div className="form-check edit-theme-form-check">
          <input
            type="checkbox"
            className="check-input edit-theme-form-check-input"
            id="isCustomizable"
            checked={isCustomizable}
            onChange={(e) => setIsCustomizable(e.target.checked)}
          />
          <label htmlFor="isCustomizable" className="form-check-label edit-theme-form-check-label">Customizable</label>
        </div>
        {isCustomizable && (
          <fieldset className="edit-theme-custom-fields">
            <legend className="edit-theme-custom-fields-legend">Custom Fields</legend>
            {customFields.map((field, index) => (
              <div key={index} className="input-group edit-theme-input-group mb-2">
                <input
                  type="text"
                  className="form-control edit-theme-form-control"
                  value={field}
                  onChange={(e) => handleCustomFieldChange(index, e.target.value)}
                />
                <button
                  type="button"
                  className="btn-danger edit-theme-btn-danger"
                  onClick={() => handleRemoveCustomField(index)}
                >
                  <i className="bi bi-trash"></i>
                </button>
              </div>
            ))}
            <button
              type="button"
              className="btn btn-secondary edit-theme-btn-secondary mt-2"
              onClick={handleAddCustomField}
            >
              <i className="bi bi-plus-circle"></i> Add Custom Field
            </button>
          </fieldset>
        )}
        <button type="submit" className="btn btn-primary edit-theme-btn-primary">
          <i className="bi bi-arrow-repeat me-2"></i> Update Theme
        </button>
      </form>

      <div className="row mt-4">
        <fieldset className="edit-theme-snapshots-fieldset">
          <legend className="edit-theme-snapshots-legend">Current Snapshots</legend>
          <Carousel className="edit-theme-carousel">
            {lastVersionSnapshots.map((snapshot, index) => (
              <Carousel.Item key={index}>
                <img
                  src={STRAPI_URL + snapshot.url}
                  alt={snapshot.name}
                  className="d-block w-100 edit-theme-carousel-img"
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </fieldset>
      </div>

      {/* Modal para adição de uma nova versão */}
      <Modal show={showVersionForm} onHide={() => setShowVersionForm(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Version</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddThemeVersion themeId={id} onVersionAdded={handleVersionAdded} onCancel={() => setShowVersionForm(false)} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditTheme;
