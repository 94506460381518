// src/helpers/api/services/authService.js
import axiosInstance from '../axiosConfig';
import showToast from '../../notifications/showToast';

const AUTH_SESSION_KEY = 'auth_user';

const register = async (username, email, password) => {
  try {
    const response = await axiosInstance.post('/api/auth/local/register', {
      username,
      email,
      password,
    });
    localStorage.setItem(AUTH_SESSION_KEY, JSON.stringify({ token: response.data.jwt }));
    return response.data;
  } catch (error) {
    showToast('Erro ao criar usuário', 'error', 'top-end', 2000);

    throw error;
  }
};

export { register };
