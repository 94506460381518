import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../styles/footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Obter o ano atual

  return (
    <footer className="bg-dark text-white text-center py-3 mt-auto">
      <Container>
        <Row>
          <Col>
            <p className="mb-0">Theme Manager App</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="mb-0">Criado por André Vilas Boas Fagundes</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="mb-0">&copy; {currentYear} Todos os direitos reservados.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
