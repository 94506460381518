import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosInstance from '../helpers/api/axiosConfig';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('auth_token');
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setUser({ token }); // Pode ser substituído por dados reais do usuário
    }
    setLoading(false);
  }, []);

  const login = (userData) => {
    localStorage.setItem('auth_token', userData.jwt);
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${userData.jwt}`;
    setUser(userData);
  };

  const logout = () => {
    localStorage.removeItem('auth_token');
    delete axiosInstance.defaults.headers.common['Authorization'];
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
