import React, { useState } from 'react';
import JSZip from 'jszip';
import { createThemeVersion } from '../helpers/api/services/themesService';
import showToast from '../helpers/notifications/showToast';
import '../styles/add-theme-version.css';
import templateValidation from '../assets/json/template-validation.json'; // Importando o JSON

const AddThemeVersion = ({ themeId, onVersionAdded, onCancel }) => {
  const [version, setVersion] = useState('');
  const [assets, setAssets] = useState(null);
  const [snapshots, setSnapshots] = useState([]);

  const handleAssetsChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const isValid = await validateZip(file);
        if (isValid) {
          setAssets(file);
        } else {
          showToast('Invalid ZIP structure. Please upload a valid template.', 'error');
          e.target.value = ''; // Limpar o input de arquivo
        }
      } catch (error) {
        console.error('Error validating ZIP file', error);
        showToast('Error validating ZIP file', 'error');
        e.target.value = ''; // Limpar o input de arquivo
      }
    }
  };

  const validateZip = async (file) => {

    if (templateValidation.expectedStructure.length === 0) {
      return true; // Se não houver estrutura a ser validada, considera-se válido
    }

    const zip = new JSZip();
    const zipContent = await zip.loadAsync(file);
    
  
    for (const expectedItem of templateValidation.expectedStructure) {
      if (!zipContent.file(expectedItem) && !zipContent.folder(expectedItem)) {
        return false; // Estrutura inválida
      }
    }
  
    return true; // Estrutura válida
  };
  

  const handleSnapshotsChange = (e) => {
    setSnapshots([...e.target.files].slice(0, 6)); // Limita a 6 arquivos
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createThemeVersion(version, assets, themeId, snapshots);
      showToast('Theme version created successfully!', 'success');
      onVersionAdded();
    } catch (error) {
      console.error('Error creating theme version', error);
      showToast('Error creating theme version', 'error');
    }
  };

  return (
    <div className="add-theme-version-container">
      <form onSubmit={handleSubmit} className="add-theme-version-form">
        <div className="form-group add-theme-version-form-group">
          <label htmlFor="version" className="form-label add-theme-version-form-label">Version</label>
          <input 
            type="text" 
            className="form-control add-theme-version-form-control" 
            id="version" 
            value={version} 
            onChange={(e) => setVersion(e.target.value)} 
            required 
          />
        </div>
        <div className="form-group add-theme-version-form-group">
          <label htmlFor="assets" className="form-label add-theme-version-form-label">Assets (.zip)</label>
          <input 
            type="file" 
            className="form-control add-theme-version-form-control" 
            id="assets" 
            onChange={handleAssetsChange} 
            required 
          />
        </div>
        <div className="form-group add-theme-version-form-group">
          <label htmlFor="snapshots" className="form-label add-theme-version-form-label">Snapshots (png or jpg, max 6)</label>
          <input 
            type="file" 
            className="form-control add-theme-version-form-control" 
            id="snapshots" 
            multiple 
            onChange={handleSnapshotsChange} 
            required 
          />
        </div>
        <div className="add-theme-version-buttons">
          <button type="submit" className="btn btn-success add-theme-version-btn">
            <i className="bi bi-plus-circle me-2"></i> Create Version
          </button>
          <button type="button" className="btn btn-secondary add-theme-version-btn-cancel ms-2" onClick={onCancel}>
            <i className="bi bi-x-circle me-2"></i> Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddThemeVersion;
