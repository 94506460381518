import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getThemeById, getThemeVersions, softDeleteThemeVersion } from '../helpers/api/services/themesService';
import { STRAPI_URL } from '../helpers/config';
import showToast from '../helpers/notifications/showToast';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../styles/history-theme.css';

const MySwal = withReactContent(Swal);

const HistoryTheme = () => {
  const { id } = useParams();
  const [versions, setVersions] = useState([]);
  const [themeName, setThemeName] = useState(''); // Novo estado para armazenar o nome do tema
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchThemeData = async () => {
      try {
        // Buscando o nome do tema
        const themeData = await getThemeById(id);
        setThemeName(themeData.attributes.name);

        // Buscando as versões do tema
        const response = await getThemeVersions(id, { allVersions: true });
        setVersions(response);
      } catch (error) {
        console.error('Error fetching theme data', error);
        showToast('Error fetching theme data', 'error');
        setError('Error fetching theme data');
      }
    };

    fetchThemeData();
  }, [id]);

  const handleDeleteVersion = async (versionId) => {
    try {
      const result = await MySwal.fire({
        title: 'Are you sure?',
        text: 'Do you really want to delete this theme version?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
      });

      if (result.isConfirmed) {
        await softDeleteThemeVersion(versionId);
        setVersions(versions.filter(version => version.id !== versionId));
        showToast('Theme version deleted successfully!', 'success');
      }
    } catch (error) {
      console.error('Error deleting theme version', error);
      showToast('Error deleting theme version', 'error');
    }
  };

  const handleSnapshotClick = (url) => {
    MySwal.fire({
      imageUrl: url,
      imageAlt: 'Snapshot',
      showCloseButton: true,
      showConfirmButton: false,
      customClass: {
        image: 'history-theme-modal-img',  // Classe personalizada para a imagem no modal
      },
    });
  };

  const handleDownload = (url, name) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    showToast('Download initiated!', 'success');
  };

  if (error) {
    return <div className="container"><p>{error}</p></div>;
  }

  return (
    <div className="container history-theme-container">
      <h2 className="history-theme-title pb-3">{themeName} &gt; History</h2> {/* Atualizando o título */}
      <div className="row">
        {versions.length > 0 ? (
          versions.map(version => (
            <div key={version.id} className="col-md-4 mb-4">
              <div className="card history-theme-card">
                <div className="card-body history-theme-card-body">
                  <div className="history-theme-card-content">
                    <h5 className="card-title history-theme-card-title">
                      <span className="badge bg-primary history-theme-badge me-2">
                        <i className="bi bi-tag"></i> Version: {version.attributes.version}
                      </span>
                    </h5>
                    <p className="card-text history-theme-card-text">
                      <span className="badge bg-warning-subtle border border-warning-subtle text-warning-emphasis history-theme-badge">
                        <i className="bi bi-calendar3 me-2"></i> Created: {new Date(version.attributes.created).toLocaleString()}
                      </span>
                    </p>
                  </div>
                  <hr />
                  <Carousel className="history-theme-carousel">
                    {version.attributes.snapshots.data.map(snapshot => (
                      <Carousel.Item key={snapshot.id}>
                        <img
                          className="d-block w-100 history-theme-img"
                          src={STRAPI_URL + snapshot.attributes.url}
                          alt={snapshot.attributes.name}
                          onClick={() => handleSnapshotClick(STRAPI_URL + snapshot.attributes.url)}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  <hr/>
                  <button 
                    className="btn btn-success btn-sm history-theme-btn"
                    onClick={() => handleDownload(
                      STRAPI_URL + version.attributes.assets.data.attributes.url,
                      version.attributes.assets.data.attributes.name
                    )}
                  >
                    <i className="bi bi-download me-2"></i> Download
                  </button>
                  <button 
                    onClick={() => handleDeleteVersion(version.id)} 
                    className="btn btn-danger btn-sm history-theme-btn mt-3"
                  >
                    <i className="bi bi-trash me-2"></i> Delete
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No versions available for this theme.</p>
        )}
      </div>
    </div>
  );
};

export default HistoryTheme;
