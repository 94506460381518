import React, { useState } from 'react';
import JSZip from 'jszip';
import { useNavigate } from 'react-router-dom';
import { createTheme, createThemeVersion } from '../helpers/api';
import showToast from '../helpers/notifications/showToast'; // Importando o showToast
import '../styles/add-theme.css';
import templateValidation from '../assets/json/template-validation.json'; // Importando o JSON

const AddTheme = () => {
  const [name, setName] = useState('');
  const [isCustomizable, setIsCustomizable] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const [assets, setAssets] = useState(null);
  const [snapshots, setSnapshots] = useState([]);
  const [version, setVersion] = useState('');
  const navigate = useNavigate();

  const handleAddCustomField = () => {
    setCustomFields([...customFields, '']);
  };

  const handleCustomFieldChange = (index, value) => {
    const newCustomFields = [...customFields];
    newCustomFields[index] = value;
    setCustomFields(newCustomFields);
  };

  const handleCustomFieldRemove = (index) => {
    const newCustomFields = customFields.filter((_, i) => i !== index);
    setCustomFields(newCustomFields);
  };

  const handleAssetsChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const isValid = await validateZip(file);
        if (isValid) {
          setAssets(file);
          showToast('ZIP file validated successfully!', 'success');
        } else {
          showToast('Invalid ZIP structure. Please upload a valid template.', 'error');
          e.target.value = ''; // Limpar o input de arquivo
        }
      } catch (error) {
        console.error('Error validating ZIP file', error);
        showToast('Error validating ZIP file', 'error');
        e.target.value = ''; // Limpar o input de arquivo
      }
    }
  };

  const validateZip = async (file) => {

    if (templateValidation.expectedStructure.length === 0) {
      return true; // Se não houver estrutura a ser validada, considera-se válido
    }

    const zip = new JSZip();
    const zipContent = await zip.loadAsync(file);
    
  
    for (const expectedItem of templateValidation.expectedStructure) {
      if (!zipContent.file(expectedItem) && !zipContent.folder(expectedItem)) {
        return false; // Estrutura inválida
      }
    }
  
    return true; // Estrutura válida
  };

  const handleSnapshotsChange = (e) => {
    setSnapshots([...e.target.files].slice(0, 6)); // Limita a 6 arquivos
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const theme = await createTheme(name, isCustomizable, customFields);
      await createThemeVersion(version, assets, theme.id, snapshots);
      showToast('Theme and Theme Version created successfully!', 'success');
      navigate('/themes');
    } catch (error) {
      console.error('Error creating theme or theme version', error);
      showToast('Error creating theme or theme version', 'error');
    }
  };

  return (
    <div className="add-theme-container">
      <h2 className="add-theme-title">New Theme</h2>
      <form onSubmit={handleSubmit} className="add-theme-form">
        <div className="add-theme-form-group">
          <label htmlFor="name" className="add-theme-form-label">Name</label>
          <input 
            type="text" 
            className="add-theme-form-control" 
            id="name" 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
            required 
          />
        </div>
        <div className="add-theme-form-group">
          <label htmlFor="version" className="add-theme-form-label">Version</label>
          <input 
            type="text" 
            className="add-theme-form-control" 
            id="version" 
            value={version} 
            onChange={(e) => setVersion(e.target.value)} 
            required 
          />
        </div>
        <div className="add-theme-form-group">
          <label htmlFor="assets" className="add-theme-form-label">Assets (.zip)</label>
          <input 
            type="file" 
            className="add-theme-form-control" 
            id="assets" 
            onChange={handleAssetsChange} 
            required 
          />
        </div>
        <div className="add-theme-form-group">
          <label htmlFor="snapshots" className="add-theme-form-label">Snapshots (png or jpg, max 6)</label>
          <input 
            type="file" 
            className="add-theme-form-control" 
            id="snapshots" 
            multiple 
            onChange={handleSnapshotsChange} 
            required 
          />
        </div>
        <div className="add-theme-form-check">
          <input 
            type="checkbox" 
            className="add-theme-form-check-input" 
            id="isCustomizable" 
            checked={isCustomizable} 
            onChange={(e) => setIsCustomizable(e.target.checked)} 
          />
          <label htmlFor="isCustomizable" className="add-theme-form-check-label">Customizable</label>
        </div>
        {isCustomizable && (
          <fieldset className="add-theme-custom-fields mt-4">
            <legend className="add-theme-custom-fields-legend">Custom Properties</legend>
            {customFields.map((field, index) => (
              <div key={index} className="add-theme-input-group mb-2">
                <input 
                  type="text" 
                  className="add-theme-form-control" 
                  value={field} 
                  onChange={(e) => handleCustomFieldChange(index, e.target.value)} 
                />
                <button 
                  type="button" 
                  className="add-theme-btn-danger" 
                  onClick={() => handleCustomFieldRemove(index)}
                >
                  <i className="bi bi-trash"></i>
                </button>
              </div>
            ))}
            <button 
              type="button" 
              className="add-theme-btn-secondary mt-2" 
              onClick={handleAddCustomField}
            >
              <i className="bi bi-plus-circle"></i> Add Custom Property
            </button>
          </fieldset>
        )}
        <button type="submit" className="add-theme-btn-primary mt-4">Save Theme</button>
      </form>
    </div>
  );
};

export default AddTheme;
