import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { login as loginUser } from '../helpers/api/services/loginService';
import '../styles/login.css';
import loginImage from '../assets/images/login-1.jpg';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = await loginUser(email, password);
      login(userData);
      navigate('/themes');
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  return (
    <section className="login-container text-center text-lg-start">
      <div className="card login-card mb-3">
        <div className="row g-0 d-flex align-items-center">
          <div className="col-lg-4 d-none d-lg-flex">
            <img
              src={loginImage}
              alt="Trendy Pants and Shoes"
              className="w-100"
            />
          </div>
          <div className="col-lg-8">
            <div className="card-body card-body-login py-5 px-md-5">
              {/* Nome da Aplicação com Ícone */}
              <h1 className="text-center mb-4 theme-manager-title">
                <i className="bi bi-palette-fill me-2"></i>
                Theme Manager
              </h1>

              <form onSubmit={handleSubmit}>
                {/* Email input */}
                <div className="form-outline mb-4">
                  <input
                    type="email"
                    id="form2Example1"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <label className="form-label" htmlFor="form2Example1">
                    Email address
                  </label>
                </div>

                {/* Password input */}
                <div className="form-outline mb-4">
                  <input
                    type="password"
                    id="form2Example2"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <label className="form-label" htmlFor="form2Example2">
                    Password
                  </label>
                </div>

                {/* Submit button */}
                <button type="submit" className="btn btn-primary btn-block mb-4 justify-content-center align-items-center">
                  Log In
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
