import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getThemes, softDeleteTheme as deleteTheme, getThemeVersions } from '../helpers/api';
import { STRAPI_URL } from '../helpers/config';
import showToast from '../helpers/notifications/showToast';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import '../styles/themes.css';
import { Dropdown } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';

const MySwal = withReactContent(Swal);

const Themes = () => {
  const [themes, setThemes] = useState([]);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchThemesAndVersions = async () => {
      const searchParams = new URLSearchParams(location.search);
      const searchTerm = searchParams.get('search') || '';

      try {
        const themes = await getThemes(searchTerm);
        const themesWithVersions = await Promise.all(
          themes.map(async (theme) => {
            const versions = await getThemeVersions(theme.id);

            // Filtra versões que não foram removidas
            const validVersions = versions.filter(version => !version.attributes.isDeleted);

            // Pega a última versão válida
            const latestVersion = validVersions.length > 0 ? validVersions[0] : null;

            return { 
              ...theme, 
              latestVersion, 
              updated: latestVersion ? new Date(latestVersion.attributes.createdAt).toLocaleString() : theme.attributes.updated 
            };
          })
        );
        setThemes(themesWithVersions);
      } catch (error) {
        console.log('Error fetching themes', error);
        setError('Error fetching themes');
        showToast('Error fetching themes', 'error');
      }
    };

    fetchThemesAndVersions();
  }, [location.search]);

  const handleDelete = async (id) => {
    try {
      const result = await MySwal.fire({
        title: 'Are you sure?',
        text: 'Do you really want to delete this theme?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
      });

      if (result.isConfirmed) {
        await deleteTheme(id);
        setThemes(themes.filter(theme => theme.id !== id));
        showToast('Theme deleted successfully!', 'success');
      }
    } catch (error) {
      setError('Error deleting theme');
      showToast('Error deleting theme', 'error');
    }
  };

  const handleDownload = async (url, name) => {
    try {
      const link = document.createElement('a');
      link.href = url;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      showToast('Download initiated!', 'success');
    } catch (error) {
      console.error('Error downloading file', error);
      showToast('Error downloading file', 'error');
    }
  };

  if (error) {
    return <div className="themes-container"><p>{error}</p></div>;
  }

  return (
    <div className="container themes-container">
      <div className="themes-header-row">
        <h2>Themes</h2>
        <Link to="/add-theme" className="btn btn-primary themes-btn-primary themes-btn-add">
          <i className="bi bi-plus-circle"></i> Add Theme
        </Link>
      </div>
      <div className="row">
        {Array.isArray(themes) && themes.map(theme => (
          <div key={theme.id} className="col-md-4 mb-4">
            <div className="card themes-card">
              <div className="card-header themes-card-header">
                {theme.attributes.name}
                <Dropdown align="end">
                  <Dropdown.Toggle variant="light" id="dropdown-basic">
                    <i className="bi bi-three-dots"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="themes-dropdown-menu">
                    <Dropdown.Item as={Link} to={`/edit-theme/${theme.id}`} className="themes-dropdown-item">
                      <i className="bi bi-pencil-square me-2"></i> Edit
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDelete(theme.id)} className="themes-dropdown-item">
                      <i className="bi bi-trash3 me-2"></i> Delete
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to={`/history-theme/${theme.id}`} className="themes-dropdown-item">
                      <i className="bi bi-clock-history me-2"></i> History
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="card-body themes-card-body">
                {theme.latestVersion?.attributes.snapshots?.data?.length > 0 && (
                  <img 
                    src={STRAPI_URL + theme.latestVersion.attributes.snapshots.data[0].attributes.url} 
                    alt={`${theme.attributes.name} snapshot`} 
                    className="img-fluid themes-img-fluid mb-3" 
                  />
                )}
                <div className="themes-badge-container">
                  <span className="badge bg-warning-subtle border border-warning-subtle text-warning-emphasis themes-badge-custom">
                    <i className="bi bi-calendar3 me-2"></i> Created: {new Date(theme.attributes.created).toLocaleString()}
                  </span>
                  <span className="badge bg-info-subtle border border-info-subtle text-info-emphasis themes-badge-custom">
                    <i className="bi bi-calendar-event me-2"></i> Updated: {theme.updated}
                  </span>
                  <span className="badge bg-success-subtle border border-success-subtle text-success-emphasis themes-badge-custom">
                    <i className="bi bi-gear me-2"></i> Customizable: {theme.attributes.isCustomizable ? 'Yes' : 'No'}
                  </span>
                </div>
                {theme.latestVersion?.attributes.assets?.data && (
                  <button 
                    onClick={() => handleDownload(
                      STRAPI_URL + theme.latestVersion.attributes.assets.data.attributes.url,
                      theme.latestVersion.attributes.assets.data.attributes.name
                    )} 
                    className="btn btn-success themes-btn-success btn-sm mt-3"
                  >
                    <i className="bi bi-download me-2"></i> Download
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Themes;
