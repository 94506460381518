import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar, Form, FormControl, Dropdown } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../../styles/header.css';

const Header = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState(''); // Estado para armazenar o termo de pesquisa

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm) {
      navigate(`/themes?search=${searchTerm}`); // Navega para a rota de temas com o termo de pesquisa
    }
  };

  return (
    <Navbar bg="light" expand="lg" className="border-bottom mb-3 py-3">
      <div className="container-fluid">
        <Dropdown>
          <Dropdown.Toggle as="a" className="nav-link dropdown-toggle text-decoration-none d-flex align-items-center">
            <i className="bi bi-palette-fill header-icon me-2"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as={Link} to="/themes">Themes</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Form className="d-flex w-100 me-3 search-box" onSubmit={handleSearch}>
          <FormControl
            type="search"
            placeholder="Search..."
            className="me-2"
            aria-label="Search"
            value={searchTerm} // Valor do input controlado pelo estado
            onChange={(e) => setSearchTerm(e.target.value)} // Atualiza o estado ao digitar
          />
        </Form>

        {user && (
          <Dropdown align="end" className="d-inline mx-2">
            <Dropdown.Toggle as="a" className="d-block link-body-emphasis text-decoration-none">
              <i className="bi bi-person-circle header-icon"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/add-theme">New theme...</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleLogout}>Sign out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    </Navbar>
  );
};

export default Header;
