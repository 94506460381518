import axios from 'axios';
import { STRAPI_URL } from '../../config';
import showToast from '../../notifications/showToast';

const axiosForAuth = axios.create({
  baseURL: STRAPI_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const login = async (email, password) => {
  try {
    const response = await axiosForAuth.post('/api/auth/local', {
      identifier: email,
      password,
    });
    return response.data;
  } catch (error) {
    showToast('Verifique seu nome de usuário e senha', 'error', 'top-end', 2000);
    throw error;
  }
};

export { login };
