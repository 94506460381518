import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../components/Login';
import Register from '../components/Register';
import Themes from '../components/Themes';
import AddTheme from '../components/AddTheme';
import EditTheme from '../components/EditTheme';
import HistoryTheme from '../components/HistoryTheme';
import Users from '../components/Users';
import PrivateRoute from './PrivateRoute';
import Layout from '../components/common/Layout';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route element={<PrivateRoute />}>
        <Route element={<Layout />}>
          <Route path="/themes" element={<Themes />} />
          <Route path="/add-theme" element={<AddTheme />} />
          <Route path="/edit-theme/:id" element={<EditTheme />} />
          <Route path="/history-theme/:id" element={<HistoryTheme />} />
          <Route path="/users" element={<Users />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default AppRoutes;
