// src/helpers/notifications/showToast.js

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const showToast = (message, type = 'warning', position = 'top-end', timer = 2000, redirectOnClose = false) => {
  MySwal.fire({
    toast: true,
    icon: type,
    title: message,
    position: position,
    showConfirmButton: false,
    timer: timer,
    timerProgressBar: true,
    didClose: () => {
      if (redirectOnClose) {
        window.location.href = "/login";
      }
    },
  });
};

export default showToast;
