import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Importando os ícones do Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'; // Importando o CSS do Bootstrap
import { Offcanvas } from 'react-bootstrap';
import '../../styles/sidebar.css';

const Sidebar = () => {
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);

  return (
    <>
      {/* Sidebar usando Offcanvas */}
      <Offcanvas show={show} onHide={handleClose} responsive="lg">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="list-unstyled">
            <li className="sidebar-item"> {/* Classe personalizada para espaçamento */}
              <Link to="/themes" className="text-decoration-none d-flex align-items-center">
                <i className="bi bi-layout-text-sidebar-reverse me-2"></i> {/* Ícone adicionado */}
                Themes
              </Link>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Sidebar;
