import axios from 'axios';
import { STRAPI_URL } from '../config';
import showToast from '../notifications/showToast';

const axiosInstance = axios.create({
  baseURL: STRAPI_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      showToast('Você não tem permissão para acessar essa página!', 'warning', 'top-end', 2000);
    } else if (error.response) {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
