import axiosInstance from '../axiosConfig';
import showToast from '../../notifications/showToast';

// Função para fazer o upload de um único arquivo
const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append('files', file);

  showToast(`Uploading file: ${file.name}`, 'info', 'top-end', 2000);

  try {
    const response = await axiosInstance.post('/api/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    showToast(`File uploaded: ${file.name}`, 'success', 'top-end', 2000);
    return response.data[0]; // Retorna o primeiro arquivo carregado
  } catch (error) {
    console.error('Error uploading file', error);
    showToast(`Error uploading file: ${file.name}`, 'error', 'top-end', 2000);
    throw error;
  }
};

const getThemes = async (searchTerm = '') => {
  try {
    const response = await axiosInstance.get('/api/themes', {
      params: {
        'filters[isDeleted][$eq]': false, // Filtra temas que não foram deletados
        ...(searchTerm && { 'filters[name][$contains]': searchTerm }), // Adiciona filtro de pesquisa se houver um termo
      },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching themes', error.response ? error.response.data : error.message);
    showToast('Error fetching themes', 'error');
    throw error;
  }
};

const createTheme = async (name, isCustomizable, customFields) => {
  try {
    const response = await axiosInstance.post('/api/themes', {
      data: {
        name,
        isCustomizable,
        customFields,
        created: new Date().toISOString(),
        updated: new Date().toISOString(),
        isDeleted: false, // Garante que o novo tema não esteja deletado
      }
    });
    return response.data.data;
  } catch (error) {
    console.error('Error creating theme', error);
    showToast('Error creating theme', 'error');
    throw error;
  }
};

const createThemeVersion = async (version, assets, themeId, snapshots) => {
  try {
    // Upload de assets
    const uploadedAsset = await uploadFile(assets);
    const assetId = uploadedAsset.id; // Isso obterá o ID do único arquivo carregado

    // Upload de snapshots
    const uploadedSnapshots = await Promise.all(Array.from(snapshots).map(file => uploadFile(file)));
    const snapshotIds = uploadedSnapshots.map(snapshot => snapshot.id);

    const response = await axiosInstance.post('/api/theme-versions', {
      data: {
        version,
        theme: themeId,
        created: new Date().toISOString(),
        assets: assetId,
        snapshots: snapshotIds,
        isDeleted: false, // Garante que a nova versão não esteja deletada
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error creating theme version', error);
    showToast('Error creating theme version', 'error');
    throw error;
  }
};

const softDeleteTheme = async (id) => {
  try {
    const response = await axiosInstance.put(`/api/themes/${id}`, {
      data: {
        isDeleted: true,
      }
    });
    showToast('Theme deleted successfully!', 'success');
    return response.data.data;
  } catch (error) {
    console.error('Error deleting theme', error);
    showToast('Error deleting theme', 'error');
    throw error;
  }
};

const softDeleteThemeVersion = async (id) => {
  try {
    const response = await axiosInstance.put(`/api/theme-versions/${id}`, {
      data: {
        isDeleted: true,
      }
    });
    showToast('Theme version deleted successfully!', 'success');
    return response.data.data;
  } catch (error) {
    console.error('Error deleting theme version', error);
    showToast('Error deleting theme version', 'error');
    throw error;
  }
};

const getThemeVersions = async (themeId, options = {}) => {
  try {
    const params = {
      'filters[theme][id][$eq]': themeId,
      'filters[isDeleted][$eq]': false, // Filtra versões que não foram deletadas
      'sort': 'createdAt:desc',
      'populate': '*',  // Inclui todos os relacionamentos
    };

    if (options.allVersions) {
      delete params['pagination[limit]'];
    } else {
      params['pagination[limit]'] = 1;
    }

    const response = await axiosInstance.get(`/api/theme-versions`, { params });
    
    // Retorna sempre um array, mesmo se estiver vazio ou com apenas um item
    const versions = response.data.data || [];
    return options.allVersions ? versions : versions[0] ? [versions[0]] : [];
  } catch (error) {
    console.error('Error fetching theme versions', error);
    throw error;
  }
};


const getThemeById = async (id) => {
  try {
    const response = await axiosInstance.get(`/api/themes/${id}`, {
      params: {
        'filters[isDeleted][$eq]': false, // Filtra tema que não foi deletado
      },
    });
    return response.data.data; // Ajuste conforme a estrutura de resposta da API
  } catch (error) {
    console.error('Error fetching theme', error);
    showToast('Error fetching theme', 'error');
    throw error;
  }
};

const updateTheme = async (id, themeData) => {
  try {
    const response = await axiosInstance.put(`/api/themes/${id}`, {
      data: themeData,
    });
    return response.data.data; // Ajuste conforme a estrutura de resposta da API
  } catch (error) {
    console.error('Error updating theme', error);
    showToast('Error updating theme', 'error');
    throw error;
  }
};

export { getThemes, createTheme, createThemeVersion, softDeleteTheme, softDeleteThemeVersion, getThemeVersions, getThemeById, updateTheme };
